import _readableStream from "readable-stream";
import _endOfStream from "end-of-stream";
import _inherits from "inherits";
import _streamShift from "stream-shift";
import _process from "process";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;
var process = _process;
var stream = _readableStream;
var eos = _endOfStream;
var inherits = _inherits;
var shift = _streamShift;
var SIGNAL_FLUSH = Buffer.from && Buffer.from !== Uint8Array.from ? Buffer.from([0]) : new Buffer([0]);

var onuncork = function (self, fn) {
  if (self._corked) self.once("uncork", fn);else fn();
};

var autoDestroy = function (self, err) {
  if (self._autoDestroy) self.destroy(err);
};

var destroyer = function (self, end) {
  return function (err) {
    if (err) autoDestroy(self, err.message === "premature close" ? null : err);else if (end && !self._ended) self.end();
  };
};

var end = function (ws, fn) {
  if (!ws) return fn();
  if (ws._writableState && ws._writableState.finished) return fn();
  if (ws._writableState) return ws.end(fn);
  ws.end();
  fn();
};

var toStreams2 = function (rs) {
  return new stream.Readable({
    objectMode: true,
    highWaterMark: 16
  }).wrap(rs);
};

var Duplexify = function (writable, readable, opts) {
  if (!((this || _global) instanceof Duplexify)) return new Duplexify(writable, readable, opts);
  stream.Duplex.call(this || _global, opts);
  (this || _global)._writable = null;
  (this || _global)._readable = null;
  (this || _global)._readable2 = null;
  (this || _global)._autoDestroy = !opts || opts.autoDestroy !== false;
  (this || _global)._forwardDestroy = !opts || opts.destroy !== false;
  (this || _global)._forwardEnd = !opts || opts.end !== false;
  (this || _global)._corked = 1; // start corked

  (this || _global)._ondrain = null;
  (this || _global)._drained = false;
  (this || _global)._forwarding = false;
  (this || _global)._unwrite = null;
  (this || _global)._unread = null;
  (this || _global)._ended = false;
  (this || _global).destroyed = false;
  if (writable) this.setWritable(writable);
  if (readable) this.setReadable(readable);
};

inherits(Duplexify, stream.Duplex);

Duplexify.obj = function (writable, readable, opts) {
  if (!opts) opts = {};
  opts.objectMode = true;
  opts.highWaterMark = 16;
  return new Duplexify(writable, readable, opts);
};

Duplexify.prototype.cork = function () {
  if (++(this || _global)._corked === 1) this.emit("cork");
};

Duplexify.prototype.uncork = function () {
  if ((this || _global)._corked && --(this || _global)._corked === 0) this.emit("uncork");
};

Duplexify.prototype.setWritable = function (writable) {
  if ((this || _global)._unwrite) this._unwrite();

  if ((this || _global).destroyed) {
    if (writable && writable.destroy) writable.destroy();
    return;
  }

  if (writable === null || writable === false) {
    this.end();
    return;
  }

  var self = this || _global;
  var unend = eos(writable, {
    writable: true,
    readable: false
  }, destroyer(this || _global, (this || _global)._forwardEnd));

  var ondrain = function () {
    var ondrain = self._ondrain;
    self._ondrain = null;
    if (ondrain) ondrain();
  };

  var clear = function () {
    self._writable.removeListener("drain", ondrain);

    unend();
  };

  if ((this || _global)._unwrite) process.nextTick(ondrain); // force a drain on stream reset to avoid livelocks

  (this || _global)._writable = writable;

  (this || _global)._writable.on("drain", ondrain);

  (this || _global)._unwrite = clear;
  this.uncork(); // always uncork setWritable
};

Duplexify.prototype.setReadable = function (readable) {
  if ((this || _global)._unread) this._unread();

  if ((this || _global).destroyed) {
    if (readable && readable.destroy) readable.destroy();
    return;
  }

  if (readable === null || readable === false) {
    this.push(null);
    this.resume();
    return;
  }

  var self = this || _global;
  var unend = eos(readable, {
    writable: false,
    readable: true
  }, destroyer(this || _global));

  var onreadable = function () {
    self._forward();
  };

  var onend = function () {
    self.push(null);
  };

  var clear = function () {
    self._readable2.removeListener("readable", onreadable);

    self._readable2.removeListener("end", onend);

    unend();
  };

  (this || _global)._drained = true;
  (this || _global)._readable = readable;
  (this || _global)._readable2 = readable._readableState ? readable : toStreams2(readable);

  (this || _global)._readable2.on("readable", onreadable);

  (this || _global)._readable2.on("end", onend);

  (this || _global)._unread = clear;

  this._forward();
};

Duplexify.prototype._read = function () {
  (this || _global)._drained = true;

  this._forward();
};

Duplexify.prototype._forward = function () {
  if ((this || _global)._forwarding || !(this || _global)._readable2 || !(this || _global)._drained) return;
  (this || _global)._forwarding = true;
  var data;

  while ((this || _global)._drained && (data = shift((this || _global)._readable2)) !== null) {
    if ((this || _global).destroyed) continue;
    (this || _global)._drained = this.push(data);
  }

  (this || _global)._forwarding = false;
};

Duplexify.prototype.destroy = function (err) {
  if ((this || _global).destroyed) return;
  (this || _global).destroyed = true;
  var self = this || _global;
  process.nextTick(function () {
    self._destroy(err);
  });
};

Duplexify.prototype._destroy = function (err) {
  if (err) {
    var ondrain = (this || _global)._ondrain;
    (this || _global)._ondrain = null;
    if (ondrain) ondrain(err);else this.emit("error", err);
  }

  if ((this || _global)._forwardDestroy) {
    if ((this || _global)._readable && (this || _global)._readable.destroy) (this || _global)._readable.destroy();
    if ((this || _global)._writable && (this || _global)._writable.destroy) (this || _global)._writable.destroy();
  }

  this.emit("close");
};

Duplexify.prototype._write = function (data, enc, cb) {
  if ((this || _global).destroyed) return cb();
  if ((this || _global)._corked) return onuncork(this || _global, (this || _global)._write.bind(this || _global, data, enc, cb));
  if (data === SIGNAL_FLUSH) return this._finish(cb);
  if (!(this || _global)._writable) return cb();
  if ((this || _global)._writable.write(data) === false) (this || _global)._ondrain = cb;else cb();
};

Duplexify.prototype._finish = function (cb) {
  var self = this || _global;
  this.emit("preend");
  onuncork(this || _global, function () {
    end(self._forwardEnd && self._writable, function () {
      // haxx to not emit prefinish twice
      if (self._writableState.prefinished === false) self._writableState.prefinished = true;
      self.emit("prefinish");
      onuncork(self, cb);
    });
  });
};

Duplexify.prototype.end = function (data, enc, cb) {
  if (typeof data === "function") return this.end(null, null, data);
  if (typeof enc === "function") return this.end(data, null, enc);
  (this || _global)._ended = true;
  if (data) this.write(data);
  if (!(this || _global)._writableState.ending) this.write(SIGNAL_FLUSH);
  return stream.Writable.prototype.end.call(this || _global, cb);
};

exports = Duplexify;
export default exports;